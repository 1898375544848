/**
 * Write-only the password as cookie
 */
import React, { useState } from 'react';
import { setSessionPassword } from '../utils/utils';

const PasswordProtect = () => {
  const [password, setPassword] = useState('');

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <div className="container flex justify-center p-8 mt-16 lg:mt-32 md:p-16">
        <div className="flex flex-col flex-wrap justify-center align-middle">
            <h1 className="text-secondary-dark dark:text-white">Welcome</h1>
            <h4 className="pb-4 text-secondary-dark dark:text-white">Enter Password</h4>

            <form onSubmit={onSubmit} className="flex flex-wrap justify-center w-96">
                <input
                    name="password"
                    type="password"
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                    className="w-full h-16 px-6 border-2 rounded-full dark:border-0 text-secondary-dark border-secondary-dark"
                    autocomplete="current-password"
                />

                <button
                    className="flex items-center justify-center px-6 py-3 mt-4 ml-auto text-xs transition-all border rounded-full dark:bg-secondary-dark border-grey-medium border-opacity-40 hover:bg-grey-medium hover:bg-opacity-40"
                    type="submit"
                >
                Enter
                </button>
            </form>
        </div>
    </div>
  );
};

export default PasswordProtect;